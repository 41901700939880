import React from "react";
import { hydrate, render } from "react-dom";
import "./index.css";
import App from "./App";
import { FirebaseContext } from "./context/FirebaseContext";
import { auth } from "./util/firebase";

const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
  hydrate(
    <FirebaseContext.Provider value={{ auth }}>
      <App />
    </FirebaseContext.Provider>,
    rootElement
  );
} else {
  render(
    <FirebaseContext.Provider value={{ auth }}>
      <App />
    </FirebaseContext.Provider>,
    rootElement
  );
}
