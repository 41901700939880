import React from "react";
import { Typography } from "@mui/material";

import {
  play,
  github,
  twitter,
  YoutubeSVG,
  TwitterSVG,
  DiscordSVG,
} from "../Icons/icons";
import Button from "../Button/Button";
import "../../styles/style.css";
// import "./Footer.css";
const Footer = () => {
  return (
    <div className="footer bottom-append">
      <footer className="container text-center my-6 p-8 text-gray3">
        <div className="mx-auto w-24 h-1 my-12 bg-gradient-to-r from-gray5 to-gray4 rounded-full"></div>
        <div className="py-3">
          Get in touch. {" "}
          <a href="mailto:contact@sigmadimensions.com">
            <strong className="text-white font-bold">
              contact@sigmadimensions.com
            </strong>
          </a>
        </div>
        <div className="flex justify-center items-center my-2">
          <YoutubeSVG
            onClick={() =>
              window.open(
                "https://www.youtube.com/channel/UC9bPwuJZUD6ooKqzwdq9M9Q",
                "_blank"
              )
            }
          />
          <DiscordSVG
            onClick={() => window.open("https://github.com/myoualid", "_blank")}
          />
          <TwitterSVG
            onClick={() =>
              window.open("https://twitter.com/sigmadimensions", "_blank")
            }
          />
        </div>
        <div className="py-3">
          <a href="/about">About</a> |<a href="/terms">Terms & Conditions</a> |
        </div>
      </footer>

      <Typography variant="caption" align="center">
        Copyright &copy; 2023 Sigma Dimensions
      </Typography>
    </div>
  );
};

export default Footer;
