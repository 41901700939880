import { collection, getDocs } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";

const fetchStripeCustomerID = async (id) => {
  const db = getFirestore();
  const colRef = collection(db, "customers");
  const querySnapshot = await getDocs(colRef);

  const stripeID = await Promise.all(querySnapshot.docs.map(async (customerObj) => {
    if (customerObj.id === id) {
      console.log(customerObj.data().stripeId);
      return customerObj.data().stripeId; // Add the 'return' statement here
    }
  }));

  return stripeID[0];
};

export { fetchStripeCustomerID };