import React from "react";
import arrowPrevious from "../../assets/icons/arrow-previous.svg";

// import "./Button.css";
import "../../styles/style.css";

const Button = ({ icon, children, ...rest }) => {
  return (
    <button className="button" {...rest}>
      {icon ? <img className="" src={icon} alt="" /> : null}
      {children}
    </button>
  );
};

export default Button;
