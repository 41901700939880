import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  GithubAuthProvider,
  TwitterAuthProvider,
  FacebookAuthProvider ,
  OAuthProvider ,
  signInWithPopup,
  signInWithRedirect,
  signOut,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from "firebase/auth";

import { getFirestore, collection, getDocs } from "firebase/firestore";

// import FIREBASE_API_KEY from .env file with dotenv
// require('dotenv').config()
// process.env.FIREBASE_API_KEY
const config = {
  apiKey: "AIzaSyDIBO_s_v0UFntBRcUUDoGuqlBs8WCZpjg",
  authDomain: "digital-academy-cacff.firebaseapp.com",
  projectId: "digital-academy-cacff",
  storageBucket: "digital-academy-cacff.appspot.com",
  messagingSenderId: "482118195998",
  appId: "1:482118195998:web:f8f1f40761c0bfe16c070f",
  measurementId: "G-2N67LRBX9J"
};

// Initialize Firebase
const firebase = initializeApp(config);
const auth = getAuth(firebase);

const db = getFirestore();
const colRef = collection(db, "products");
const paidCourses = {};

const fetchCourses = async () => {
  const querySnapshot = await getDocs(colRef);

  await Promise.all(querySnapshot.docs.map(async (courseDoc) => {
    paidCourses[courseDoc.id] = courseDoc.data();
    const priceRef = collection(db, `products/${courseDoc.id}/prices`);
    const priceSnapshot = await getDocs(priceRef);
    priceSnapshot.docs.forEach((priceDoc) => {
      paidCourses[courseDoc.id].prices = {
        currency: priceDoc.data().currency,
        unit_amount: priceDoc.data().unit_amount,
        cost_value: priceDoc.data().unit_amount / 100,
      };
    });
  }));

};

await fetchCourses();

const handleSignOut = () => {
  signOut(auth)
    .then(() => {
      console.log("User Signed Out");
    })
    .catch((error) => {
      console.log(error);
    });
};

const signUpWithEmail = async (auth, email, password, callbackError, callbackSuccess) => {
  const { user } = await createUserWithEmailAndPassword(auth, email, password);
  if (user) {
    callbackSuccess("Successfully signed up")
  }
  else {
    callbackError("Failed to sign up. Please try again.");
  }
};

const signInwithEmail = (auth, email, password, callbackError, callbackSuccess) => {
  signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      // if signed in, route to home page
      if (userCredential.user) {
        console.log("Successfully signed in");
        callbackSuccess("Successfully signed in")
      }
    })
    .catch((error) => {
      console.log(error);
      callbackError("Failed to sign in. Please try again.");
    });
};


const signInwithProvider = (auth, company, callbackError, callbackSuccess) => {

  let provider;
  if (company === "google") {
    provider = new GoogleAuthProvider();
  }
  else if (company === "github") {
    provider = new GithubAuthProvider();
  }
  else if (company === "twitter") {
    provider = new TwitterAuthProvider();
  }
  else if (company === "facebook") {
    provider = new FacebookAuthProvider();
  }
  else if (company === "microsoft") {
    provider = new OAuthProvider('microsoft.com')
  }

  else {
    provider = new GoogleAuthProvider();
  }
  
  signInWithPopup(auth, provider)
    .then((result) => {
      // if signed in, route to home page
      if (result.user) {
        const user = result.user;
        console.log("Successfully signed in");
        callbackSuccess(`Successfully signed in ${result.user.email}`)
        return true;
      }
    }
    )
    .catch((error) => {
      console.log(error.message);
      callbackError(error.message);
    }
    );


};


export {
  db, // currnetly used in refactor
  auth, // currently used in refactor
  paidCourses,
  handleSignOut,
  signUpWithEmail,
  signInwithEmail,
  signInwithProvider,
};
