import React, {
  Fragment,
  lazy,
  Suspense,
  startTransition,
  useState,
} from "react";
import { Header, Footer } from "./components";
import { UserContext } from "./context/UserContext";
import { CourseContext } from "./context/CourseContext";
import { useAuthListener } from "./hooks";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import * as ROUTES from "./constants/routes";
import "./App.css";

const Home = lazy(() => import("./pages/Home"));
const Terms = lazy(() => import("./pages/Terms"));
const SignIn = lazy(() => import("./pages/SignIn"));
const About = lazy(() => import("./pages/About"));
const Course = lazy(() => import("./pages/Course"));
const Page404 = lazy(() => import("./pages/404"));

function App() {
  const { user } = useAuthListener();
  const [currentCourse, setCurrentCourse] = useState(null);

  function changeTitle(title) {
    console.log("title is:", title);

    setCurrentCourse(title);
    console.log("currentCourse should have been set:", currentCourse);
  }

  return (
    <UserContext.Provider value={{ user }}>
      <CourseContext.Provider value={{ currentCourse, changeTitle }}>
        <BrowserRouter>
          <div className="App">
            <AppRoutes />
          </div>
        </BrowserRouter>
      </CourseContext.Provider>
    </UserContext.Provider>
  );
}

const AppRoutes = () => {
  const location = useLocation();
  const { pathname } = location;

  return (
    <Suspense fallback={<Fragment />}>
      <Header location={location} />
      <Routes location={location} key={pathname}>
        <Route path={ROUTES.HOME} element={<Home />} />
        <Route path={ROUTES.COURSE_PAGE} element={<Course />} />
        <Route path={ROUTES.ABOUT} element={<About />} />
        <Route path={ROUTES.TERMS} element={<Terms />} />
        <Route path={ROUTES.SIGN_IN} element={<SignIn />} />
        <Route path="*" element={<Page404 />} />
      </Routes>
      <Footer />
    </Suspense>
  );
};

export default App;
