import { useState, useEffect, useContext } from 'react';
import { FirebaseContext } from '../context/FirebaseContext';
import { onAuthStateChanged } from "firebase/auth";
import {fetchStripeCustomerID} from "../util/toolCustomer";


export default function useAuthListener() {
  const { auth } = useContext(FirebaseContext);
  const [user, setUser] = useState("");


  useEffect(() => {
    const listener = onAuthStateChanged(auth, async (authUser) => {
      if (authUser) {

        const user = {
          uid: authUser.uid,
          email: authUser.email,
          stripeId: await fetchStripeCustomerID(authUser.uid)
        }

        
        setUser(user);
      } else {
        setUser(null);
      }
    });

    return () => listener();
  }, []);

  return  {user};
}
