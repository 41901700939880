import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { handleSignOut } from "../../util/firebase";
import { FaBars } from "react-icons/fa";
import "./Header.css";
import { UserContext } from "../../context/UserContext";
import Button from "../Button/Button";
import logo from "../../assets/images/white_logo.png";
import ProfilePhoto from "../UserUI/ProfilePhoto";
import yassine from "../../assets/images/yass-black.png";

const Header = (props) => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { user } = useContext(UserContext);
  const { location } = props;
  const toggleMobileMenu = () => {
    setMobileMenuOpen((prevState) => !prevState);
  };

  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
  };

  return (
    <header className="header">
      <nav className="nav">
        {/* <h2 >{JSON.stringify(user)}</h2> */}
        <div className="nav-desktop">
          <Link to="/" className="">
            <img src={logo} alt="logo" className="logo" />
          </Link>
          <ul className="nav-list">
            <li className="nav-item">
              <Link
                to="/courses"
                className="nav-link"
                onClick={closeMobileMenu}
              >
                Courses
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/about" className="nav-link" onClick={closeMobileMenu}>
                About
              </Link>
            </li>
          </ul>

          {/* Conditional rendering based on authentication */}
          {!user ? (
            <div className="row center">
              <Link to="/signin" onClick={closeMobileMenu}>
                <button className="button">Sign In</button>
              </Link>
            </div>
          ) : (
            <div className="row center">
              <Button onClick={handleSignOut}>Sign Out</Button>
              <ProfilePhoto img={yassine} />
            </div>
          )}
        </div>

        {/* Hamburger Icon */}
        <FaBars className="nav-menu-icon" onClick={toggleMobileMenu} />

        {/* Mobile Menu */}
        <ul
          className={`nav-list-mobile ${isMobileMenuOpen ? "mobile-open" : ""}`}
        >
          <li className="nav-item">
            <Link to="/" className="nav-link" onClick={closeMobileMenu}>
              Home
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/courses" className="nav-link" onClick={closeMobileMenu}>
              Courses
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/about" className="nav-link" onClick={closeMobileMenu}>
              About
            </Link>
          </li>
          {!user ? (
            <React.Fragment>
              <li className="nav-item">
                <Link
                  to="/signin"
                  className="nav-link"
                  onClick={closeMobileMenu}
                >
                  <Button text="Sign In" />
                </Link>
              </li>
            </React.Fragment>
          ) : (
            <li className="nav-item">
              <Button text="Sign Out" onClick={handleSignOut} />
            </li>
          )}
        </ul>
      </nav>
    </header>
  );
};

export default Header;
