import React from "react";
import './ProfilePhoto.css'

const ProfilePhoto = ({ img, ...rest }) => {
  return (
    <>
        <img
          className="profileImage-small"
          alt=""
          src={img}
          style={{ width: "inherit" }}
        />
    </>
  );
};

export default ProfilePhoto;
